import ConfigManager from '@/config/ConfigManager.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import { map } from 'rxjs/operators'
import { config } from '@/config/bzbsconfig.js'

/**
 * @method apiRequestOTP
 * @method apiLogin
 * @method apiLogout
 * @method apiLoginLine
 * @method apiLoginDevice
 * @method apiReLoginDevice
 */
export default {
  version: function () {
    return config.businessRule.permission.apiVersion
  },
  apiGetMenu() {
    var url = ConfigManager.getApiadB2CUrl() + '/api/'+ this.version() +'/user/menus'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
}
