import { createApp } from 'vue'
import VueMarkdownEditor from '@kangc/v-md-editor'
import '@kangc/v-md-editor/lib/style/base-editor.css'
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js'
import '@kangc/v-md-editor/lib/theme/style/vuepress.css'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

import Antd from 'ant-design-vue/lib'
import App from './App.vue'
import router from './router'
import store from './store'
import * as rxjs from 'rxjs'

// import AppConfig from "@/config/vueconfig.js";
import ErrorApiMixin from '@/mixin/ErrorApiMixin.js'
import CommonMixin from '@/mixin/CommonMixin.js'
import Tracking from '@/plugins/Tracking.js'
import Filter from '@/plugins/Filter.js'
import 'bootstrap/dist/css/bootstrap.css'
// import 'ant-design-vue/dist/antd.min.css'
import './index.css'
import '@splidejs/splide/dist/css/splide.min.css'
import VueApexCharts from 'vue3-apexcharts'
// import VueGoogleMaps from '@fawmi/vue-google-maps'

//#region global component
import VSvgIcon from '@/components/Icon/SvgIcon'
import VOverlay from '@/components/Overlay/Overlay'
import VLoading from '@/components/Loading/LoadingDefault'
import VLoadingTable from '@/components/Loading/LoadingTable'
//#endregion
import 'ant-design-vue/dist/antd.less'
// import 'ant-design-vue/dist/antd.css';

// import ClickOutsideDirective from "@/plugins/directive/clickOutside"
import VueClickAway from 'vue3-click-away'
//#endregion
import Vue3Lottie from 'vue3-lottie'
import 'vue3-lottie/dist/style.css'
import {
  EyeOutlined,
  ArrowLeftOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  PlusOutlined,
  EllipsisOutlined,
} from '@ant-design/icons-vue'

import Prism from 'prismjs'
VueMarkdownEditor.use(vuepressTheme, {
  Prism,
}).lang.use('en-US', enUS)

createApp(App)
  .use(VueMarkdownEditor)
  .use(rxjs)
  .use(Antd)
  .use(store)
  .use(router)
  .use(Tracking)
  .use(Filter)
  .use(Filter)

  // .use(VueClipboard)
  .use(VueClickAway)
  .use(VueApexCharts)
  .use(Vue3Lottie)
  .mixin(CommonMixin)
  .mixin(ErrorApiMixin)
  //#region registe global component
  .component('VSvgIcon', VSvgIcon)
  .component('VOverlay', VOverlay)
  .component('VLoading', VLoading)
  .component('VLoadingTable', VLoadingTable)
  .component('apexchart', VueApexCharts)
  .component('EyeOutlined', EyeOutlined)
  .component('ArrowLeftOutlined', ArrowLeftOutlined)
  .component('DeleteOutlined', DeleteOutlined)
  .component('QuestionCircleOutlined', QuestionCircleOutlined)
  .component('SearchOutlined', SearchOutlined)
  .component('PlusOutlined', PlusOutlined)
  .component('EllipsisOutlined', EllipsisOutlined)
  // .component('QuillEditor', QuillEditor)

  .mount('#app')
