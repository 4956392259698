<template>
  <div
    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 content-announcement"
  >
    <div class="row p-0">
      <div class="col-xl-8 col-lg-8 col-md-6 col-sm-6 col-12 text-left">
        <div v-if="products != null && products != ''">
          <a-tag class="product-tag">{{ products }}</a-tag>
        </div>
        <div v-else>
          <a-tag
            v-for="product of listProduct"
            :key="product.length"
            class="product-tag"
          >
            <span v-if="product.name != ''">
              {{ product.display }}
            </span>
          </a-tag>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12 text-right">
        <LayoutPreview
          :title="title"
          :content="content"
          :required="required"
          :posttype="posttype"
          :updateDate="updateDate"
        ></LayoutPreview>
        <a-dropdown :trigger="['click']">
          <a-button class="btn-option dropdown" @click.prevent
            ><ellipsis-outlined
          /></a-button>
          <template #overlay>
            <a-menu>
              <a-menu-item
                v-if="status === 'Expired'"
                @click="onClickView(announcementId)"
              >
                <a>View</a>
              </a-menu-item>
              <a-menu-item v-else @click="onClickEdit(announcementId)">
                <a>Edit</a>
              </a-menu-item>
              <a-menu-item @click="onClickDuplicate(announcementId)">
                <a>Duplicate Post</a>
              </a-menu-item>
              <a-menu-item
                v-if="status !== 'Expired'"
                @click="onClickHide(announcementId)"
                @chnge="getLIst($event)"
              >
                <a>Hide Post</a>
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </div>

    <div class="p-0 mt-1">
      <div class="content-left">
        <div
          class="item-content flex-align"
          v-for="(item, index) of statusDetail"
          :key="index"
        >
          <a-tag
            v-if="status === item.name"
            :color="item.color"
            class="tag-status"
          >
            <a-badge :status="item.status" :text="item.name" />
          </a-tag>
        </div>
        <div class="item-content short-text-width">
          <div class="title-content short-text">{{ title }}</div>
        </div>
      </div>
    </div>

    <div class="p-0 mt-1">
      <div class="content-left">
        <div class="item-content short-text-width">
          <div
            v-html="content"
            class="short-text height-content description"
          ></div>
        </div>
      </div>
    </div>

    <div class="row p-0 mt-1">
      <div
        class="col-xl-5 col-lg-5 col-md-4 col-sm-4 col-12 d-flex justify-start"
      >
        <div class="subtitle short-text">
          <a-tag class="tag-status" :color="active ? 'green' : 'default'">
            <a-badge
              :status="active ? 'success' : 'default'"
              :text="active ? 'Show' : 'Hide'"
            />
          </a-tag>
          <span>ID: {{ announcementId }}</span>
        </div>
      </div>
      <div
        class="col-xl-7 col-lg-7 col-md-8 col-sm-8 col-12 d-flex justify-end"
      >
        <div class="subtitle short-text">
          Last Update: {{ updateBy }} - {{ contentDate }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, createVNode, ref } from 'vue'
import { Modal } from 'ant-design-vue'
import LayoutPreview from './LayoutPreview.vue'
import { useRouter } from 'vue-router'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import BzbsAnnouncement from '@/core/Announcement/callapi/BzbsAnnouncement'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import useError from '@/components/Utility/useError'
dayjs.locale('en')
export default defineComponent({
  components: { LayoutPreview },
  props: {
    title: String,
    content: HTMLElement,
    status: String,
    products: Array,
    announcementId: String,
    updateBy: String,
    updateDate: String,
    startDate: String,
    active: Boolean,
    required: Boolean,
    projectList: Array,
    posttype: String,
  },
  setup(props, { emit }) {
    const router = useRouter()
    const store = useStore()
    const { reToken } = useError()
    const contentDate = ref(
      dayjs(props.updateDate).format('DD MMM YYYY - HH:mm'),
    )
    const listProduct = ref(Array)
    if (props.projectList) {
      if (props.projectList.length > 0) {
        listProduct.value = props.projectList.filter(tag => tag.name !== '')
      }
    }
    // const previewDate = ref(
    //   dayjs(props.startDate).format('DD MMM YYYY HH:mm a'),
    // )
    const onClickView = id => {
      router.push({
        name: 'viewpost',
        path: '/view/:data',
        params: { data: id },
      })
    }
    const onClickEdit = id => {
      router.push({
        name: 'editpost',
        path: '/edit/:data',
        params: { data: id },
      })
    }
    const statusDetail = [
      {
        name: 'Current Period',
        color: 'green',
        status: 'success',
      },
      {
        name: 'Inactive',
        color: 'red',
        status: 'error',
      },
      {
        name: 'Upcoming',
        color: 'blue',
        status: 'processing',
      },
      {
        name: 'Expired',
        color: 'default',
        status: 'default',
      },
    ]
    const onClickDuplicate = id => {
      Modal.confirm({
        title: 'Duplicate Post',
        icon: createVNode(QuestionCircleOutlined),
        centered: 'true',
        width: 530,
        content:
          'This setting of post will copied to new announcement. Confirm Duplicate post?',
        onOk() {
          callPostDuplicate(id)
        },
        onCancel() {},
      })
    }
    function callPostDuplicate(id) {
      store.commit('setLoading', true)
      const params = {
        id: id,
      }
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiPostDuplicate(params)
          .then(res => {
            store.commit('setLoading', false)
            let uuid = res.data.result.id
            Modal.success({
              title: 'Success',
              centered: true,
              onOk() {
                router.push({
                  name: 'editpost',
                  path: '/edit/:data',
                  params: { data: uuid },
                })
              },
            })
            resolve(res)
          })
          .catch(error => {
            store.commit('setLoading', false)
            if (error.response.status == 401) {
              reToken().then(() => {
                callPostDuplicate(id)
              })
            } else {
              let content = error
                ? error[0].message
                : 'Something went wrong, Please try again.'
              Modal.error({
                title: 'Error',
                centered: true,
                content: content,
                onOk() {},
              })
            }
            reject(error)
          })
      })
    }
    const onClickHide = id => {
      Modal.confirm({
        title: 'Hide Post',
        icon: createVNode(QuestionCircleOutlined),
        centered: 'true',
        width: 530,
        content:
          'This post will be hidden and will not publish to public. But you can change status in Edit Post until this post was expired. Confirm hide post?',
        onOk() {
          callPostHide(id)
        },
        onCancel() {},
      })
    }
    function getList() {
      emit('getList', true)
    }
    function callPostHide(id) {
      store.commit('setLoading', true)
      const params = {
        id: id,
      }
      return new Promise((resolve, reject) => {
        BzbsAnnouncement.apiPostHide(params)
          .then(res => {
            store.commit('setLoading', false)
            Modal.success({
              title: 'Success',
              centered: true,
              onOk() {
                getList()
              },
            })
            resolve(res)
          })
          .catch(error => {
            store.commit('setLoading', false)
            if (error.response.status == 401) {
              reToken().then(() => {
                callPostHide(id)
              })
            } else {
              let content = error
                ? error[0].message
                : 'Something went wrong, Please try again.'
              Modal.error({
                title: 'Error',
                centered: true,
                content: content,
                onOk() {},
              })
            }
            reject(error)
          })
      })
    }
    return {
      getList,
      onClickView,
      onClickEdit,
      onClickDuplicate,
      onClickHide,
      statusDetail,
      contentDate,
      listProduct,
    }
  },
})
</script>
<style lang="scss" scoped>
.content-announcement {
  padding: 16px;
  min-height: 144px;
  border-bottom: inset;
  .title-content {
    font-size: 16px;
    font-weight: 700;
    color: var(--grey-grey-1);
  }
  .content-left {
    display: flex;
    justify-content: left;
  }
  .content-between {
    display: flex;
    justify-content: space-between;
  }
  .item-content {
    flex-direction: column;
  }
  .short-text-width {
    width: calc(100% - 60px);
  }
  .description * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: inherit !important;
    font-weight: normal !important;
    text-decoration: unset !important;
    font-style: normal !important;
    background-color: transparent !important;
    color: var(--text-light-text-body) !important;
    text-align: left !important;
  }
  .description img {
    display: none;
  }
  .flex-tag {
    display: flex;
  }
  .product-tag {
    color: var(--grey-grey-5);
  }
  .btn-option {
    min-width: fit-content;
  }
  .status-badge {
    border-radius: 20px;
    padding: 3px;
  }
  .short-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.line-h {
      line-height: 1.5;
    }
  }
  .height-content {
    height: 22px;
  }
  .subtitle {
    font-size: 12px;
    color: var(--grey-grey-5);
  }
  button.ant-btn.btn-preview {
    margin: 0 8px;
  }
  .option-container {
    min-width: fit-content;
  }
  .flex-align {
    align-self: flex-end;
  }
  span.ant-tag.product-tag {
    margin: 0 7px 7px 0;
  }
  @media only screen and (max-width: 976px) {
    .option-container {
      display: flex;
      row-gap: 5px;
    }
  }
  @media only screen and (max-width: 576px) {
    .product-tag {
      display: none;
    }
    span.text-preview-button {
      display: none;
    }
  }
}
</style>
