// import Vue from 'vue';
import Util from '@/helper/Utility.js'
import CacheHelper from '@/helper/CacheHelper.js'
import Locale from '@/helper/locale/Locale.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsAuth from '@/core/Account/service/BzbsAuth.js'
import _ from 'lodash'
import store from '@/store/index.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())
import BzbsProfile from '@/core/Account/service/BzbsProfile.js'
import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)
let refeshToken = config.tokenRequest

//Main key.
const KEY_COOKIE_USER = 'account'
const KEY_COOKIE_FEATURES = 'bzbsFeatures'
const KEY_COOKIE_LANGUAGE = 'localVersion'

/**
 * Token
 * @method getToken
 * @method getTokenShuffle
 * @method updateToken
 *
 * Cache
 * @method getCacheUser
 * @method setCacheUser
 * @method removeCacheUser
 * @method getCachePoint
 * @method getCachePointUpdateDate
 * @method updatePoint
 *
 * Login
 * @method setLogin
 * @method setLogout
 * @method isLogin
 *
 * Api login
 * @method apiLogout
 */

export default {
  //#region user
  /**
   * Get user token.
   * @return {string|null} user token.
   */
  getToken: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.getCache('msToken') ? cache.getCache('msToken') : null
  },
  /**
   * Get shuffled user's token.
   * @return {string|null} user token.
   */
  getTokenShuffle: function () {
    var token = this.getToken()
    return Util.shuffleToken(token)
  },
  /**
   * Update user token.
   * @param {Object} strToken
   * @return {object} user.
   */
  updateToken: function (token) {
    var user = this.getCacheUser()
    user.token = token
    this.setCacheUser(user)

    return user
  },

  /**
   * Get cache user.
   * @return {object} Cache user.
   */
  getCacheUser: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.getCache(KEY_COOKIE_USER)
  },

  /**
   * Set cache user.
   * @param {object} user
   */
  setCacheUser: function (user) {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    return cache.setCache(KEY_COOKIE_USER, user)
  },

  getUserLevel: function () {
    var user = this.getCacheUser()
    if (user != null) {
      return user.userLevel
    }
    return 0
  },

  /**
   * Get point.
   * @return {number} User point.
   */
  getCachePoint: function () {
    if (this.isLogin()) {
      var user = this.getCacheUser()
      if (user != null) {
        return user.point.point
      }
    }

    return 0
  },
  /**
   * Get user's point last update date.
   * @return {number} User's point date.
   */
  getCachePointUpdateDate: function () {
    if (this.isLogin()) {
      var user = this.getCacheUser()
      if (user != null) {
        return user.point.time
      }
    }

    return 0
  },
  /**
   * Remove cache user.
   */
  removeCacheUser: function () {
    let cache = new CacheHelper(ConfigManager.getCacheType())
    cache.removeCache(KEY_COOKIE_USER)
  },

  /**
   * Set user login.
   * @param {object} user
   */
  setLogin: function (user) {
    this.setCacheUser(user)
    var localeCode = user.locale
    Locale.setLocale(localeCode)
  },

  /**
   * Set user logout.
   * @return {promises} Logout response.
   */
  setLogout: function () {
    this.removeCacheUser()
  },

  /**
   * Update point.
   * @param {object} point
   */
  updatePoint: function (point) {
    var user = this.getCacheUser()
    user.point = point
    this.setCacheUser(user)
  },

  /**
   * Check login.
   * @return {boolean} Login status.
   */
  isLogin: function () {
    var token = this.getToken()
    return !Util.isEmptyStr(token)
  },

  //#endregion user

  getMSMenu() {
    return new Promise((resolve, reject) => {
      BzbsAuth.apiGetMenu().subscribe(
        res => {
          const otherproducts = _.filter(res.result.products, product => {
            if (product.icon)
              return _.map(res.result.products, product => {
                return {
                  display_name: product.display_name,
                  icon: product.icon,
                  name: product.name,
                  url: product.url,
                }
              })
          })
          const helpcenter = _.find(res.result.products, product => {
            if (product.name == 'Help-Center')
              return _.map(res.result.products, product => {
                return {
                  display_name: product.display_name,
                  icon: product.icon,
                  name: product.name,
                  url: product.url,
                }
              })
          })
          Cache.setCache('setMenuProduct', otherproducts)
          store.commit('setMenuHelpCenter', helpcenter)
          store.commit('setMenuProduct', otherproducts)
          localStorage.setItem('setMenuProduct', JSON.stringify(otherproducts))
          resolve(res)
        },
        err => {
          this.errorApi(err)
          reject(err)
        },
      )
    })
  },
  setFeatures(data) {
    return new Promise(resolve => {
      if (data) {
        return resolve(
          localStorage.setItem(KEY_COOKIE_FEATURES, JSON.stringify(data)),
        )
      }
    })
  },
  getFeatures() {
    let bzbsFeatures = localStorage.getItem(KEY_COOKIE_FEATURES)
    if (bzbsFeatures != 'undefined') {
      return JSON.parse(bzbsFeatures)
    } else {
      return null
    }
  },
  getMSPermission(parmas) {
    return new Promise((resolve, reject) => {
      BzbsProfile.getPermission(parmas)
        .then(res => {
          resolve(res)
        })
        .catch(objerror => {
          this.checkErrorApiAD(objerror)
            .then(() => {
              this.getMSPermission(parmas).then(res => {
                resolve(res)
              })
            })
            .catch(() => {
              reject(objerror)
            })
        })
    })
  },
  checkErrorApiAD(objerror) {
    return new Promise((resolve, reject) => {
      store.commit('setLoading', false)
      if (_.has(objerror, 'response.data.error')) {
        if (objerror.response.data.error) {
          if (objerror.response.status == 401) {
            if (
              objerror.response.data.error[0].id == '2006' ||
              objerror.response.data.error[0].code == 'TokenExpired'
            ) {
              this.TokenSilent()
                .then(() => {
                  resolve()
                })
                .catch(() => {
                  reject()
                })
            } else {
              this.$router.push({
                name: 'logout',
              })
            }
          } else {
            reject()
          }
        } else {
          reject()
        }
      } else {
        reject()
      }
    })
  },
  TokenSilent() {
    return new Promise((resolve, reject) => {
      refeshToken.account = Cache.getCache('msAccount')
      myMSAL
        .acquireTokenSilent(refeshToken)
        .then(result => {
          Cache.setCache('msToken', result.accessToken)
          resolve()
        })
        .catch(() => {
          this.logout()
          reject()
        })
    })
  },
  clearCache() {
    Cache.removeCache('account')
    Cache.removeCache('msUserData')
    Cache.removeCache('bzbsFeatures')
    Cache.removeCache('msToken')
    Cache.removeCache('msAccount')
  },
  logout() {
    return new Promise(resolve => {
      store.commit('Loading', true)
      var IsMS = Cache.getCache('msUserData')
      localStorage.clear()
      this.clearCache()
      const d = new Date()
      d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie =
        'BzbsAuth=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'
      document.cookie =
        'BzbsUser=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'
      document.cookie = 'logout=true; path=/; SameSite=None; Secure'

      if (IsMS) {
        setTimeout(() => {
          const logoutRequest = {
            postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
          }
          myMSAL.logoutRedirect(logoutRequest)
        }, 0)
      } else {
        this.clearCache()
        this.$router.push({
          name: 'login',
        })
      }
      resolve()
    })
  },
  getLanguageVersion() {
    return Cache.getCache(KEY_COOKIE_LANGUAGE)
  },
  setLanguageVersion(obj) {
    return Cache.setCache(KEY_COOKIE_LANGUAGE, obj)
  },
}
