export default {
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    getBaseImage(url) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open('GET', url)
        xhr.responseType = 'blob'
        xhr.send()
        xhr.onload = () => resolve(xhr.response)
        xhr.onerror = error => reject(error)
      })
    },
    MaximumPhotoSizeUploadInMB() {
      return 3
    },
    // limit 3 MB
    sizeImage() {
      return 3145728
    },
    blobToFile(theBlob, fileName) {
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
      theBlob.lastModifiedDate = new Date()
      theBlob.name = fileName
      return theBlob
    },
  }
  