<template>
  <AppLayout :disable-menu="true">
    <div class="content-wrapper">
      <div class="auth_unauthorized">
        <a-result
          status="error"
          title="You don't have permission for this product"
        >
          <template #icon>
            <img :src="imgUnauthorized" />
          </template>
        </a-result>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { config } from '@/config/bzbsconfig.js'
import AppLayout from '@/components/Layout/LayoutDefault'
export default {
  name: 'PageUnAuthorized',
  components: {
    AppLayout,
  },
  metaInfo() {
    return {
      title: `Unauthorized - ${this.pageTitle}`,
    }
  },
  data() {
    return {
      imgUnauthorized:
        config.bzbBlobUrl + '/themebackoffice/assets/img/unauthorized.svg',
    }
  },
  created() {},
}
</script>
