<template>
  <div class="loadtable" v-if="value">
    <div class="loading">
      <Vue3Lottie
        :animationLink="loading"
        backgroundColor="transparent"
        :loop="true"
        :autoPlay="true"
        class="loaderjson"
        :height="100"
        :width="100"
      />
      Loading Data...
    </div>
  </div>
</template>

<script>
import { config } from '@/config/bzbsconfig.js'
export default {
  name: 'LoadingTable',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      json: null,
      loading: config.bzbBlobUrl + '/themebackoffice/assets/json/loading.json',
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  methods: {
    disableScroll: function () {
      document.body.classList.add('overflow-hidden')
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
  },
}
</script>
<style lang="scss" scoped>
.loadtable {
  position: absolute;
  z-index: 50;
  background: rgba(255, 255, 255, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    text-align: center;
    color: #aaa;
    img {
      width: 50px;
      margin: 0 auto 5px;
    }
  }
}
</style>
