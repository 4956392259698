<template>
  <div class="box-loader" :class="{ loaded: !$store.state.loading }">
    <Vue3Lottie
      :animationLink="loading"
      backgroundColor="transparent"
      :loop="true"
      :autoPlay="true"
      class="loaderjson"
    />
  </div>
</template>

<script>
import { config } from '@/config/bzbsconfig.js'
export default {
  data() {
    return {
      json: null,
      loading: config.bzbBlobUrl + '/themebackoffice/assets/json/loading.json',
    }
  },
  watch: {
    // '$store.state.loading': function (val) {
    //   if (val) {
    //     this.disableScroll()
    //   } else {
    //     this.enableScroll()
    //   }
    // },
  },
  methods: {
    // disableScroll: function () {
    //   document.body.classList.add('overflow-hidden')
    // },
    // enableScroll: function () {
    //   document.body.classList.remove('overflow-hidden')
    // },
    // closeLoading() {
    //   this.$store.commit('setLoading', false)
    // },
  },
}
</script>
<style lang="scss" scope>
.box-loader {
  display: none !important;
}
</style>
