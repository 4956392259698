const apiConfig = {
    b2cScopes: [
      'https://stgaadb2cbuzzebees.onmicrosoft.com/5928493b-ba84-46ef-94ea-14d53b57ef89/Access',
    ],
  }
  const b2cPolicies = {
    names: {
      signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN',
      editProfile: 'B2C_1A_PROFILE_EDIT',
      changePassword: 'B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
    },
    authorities: {
      signUpSignIn: {
        authority:
          'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN',
      },
      editProfile: {
        authority:
          'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT',
      },
      changePassword: {
        authority:
          'https://stgaadb2cbuzzebees.b2clogin.com/stgaadb2cbuzzebees.onmicrosoft.com/B2C_1A_PROFILE_EDIT_PASSWORD_CHANGE',
      },
    },
    authorityDomain: 'stgaadb2cbuzzebees.b2clogin.com',
  }
  const local = 'ph' // Philippines (PH)
  export const config = {
    local: local,
    debug: false, //enable console.log
  
    // ------------- Staging Settings ----------------
    api: 'stg',
    adB2CUrl: `https://stg-api-active-directory-b2c-wrapper-${local}.azurewebsites.net/ppperm`,
    announcementManagementAPI: `https://stg-api-product-announcement-${local}.azurewebsites.net`,
    bzbBlobUrl: `https://stgproductization${local}.blob.core.windows.net`,
  
    // ------------------ End Settings ---------------------
    fbLogOnUrl: 'https://www.facebook.com/dialog/oauth?',
    fbPermissions: ['email'],
    firebase: {
      apiKey: '',
      authDomain: '',
      databaseURL: '',
      projectId: '',
      storageBucket: '',
      messagingSenderId: '',
      appId: '',
      measurementId: '',
    },
    msalConfig: {
      auth: {
        clientId: 'a14b83d7-e26a-492d-a212-9e9b603d862d',
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: window.location.origin + '/redirectms',
        postLogoutRedirectUri: window.location.origin,
      },
      cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
      },
    },
    b2cPolicies: b2cPolicies,
    loginRequest: {
      scopes: ['openid', ...apiConfig.b2cScopes],
      // prompt: 'select_account',
    },
    tokenRequest: {
      scopes: [...apiConfig.b2cScopes],
      forceRefresh: false,
    },
  
    businessRule: {
      css: {
        version: 4,
      },
      max: {
        photoSize: 3,
      },
      permission: {
        apiVersion: 'v3.0',
      },
    },
  }
  