<template>
  <div>
  <v-md-editor
    v-model="data"
    left-toolbar="undo redo clear | h bold italic strikethrough quote | ul ol table hr | link customToolbarImage code customToolbarActionImage"
    right-toolbar="preview sync-scroll fullscreen"
    height="400px"
    :toolbar="toolbar"
    :disabled-menus="[]"
    @change="contentChange"
  ></v-md-editor>
  <input
    ref="uploadImg"
    type="file"
    accept="image/jpeg, image/jpg, image/png"
    hidden
    @change="onImgFileChange($event)"
  />
</div>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import fileReader from '@/helper/fileReader'
import { config } from '@/config/bzbsconfig.js'
import { Modal } from 'ant-design-vue'

export default defineComponent({
  props: {
    disabled: Boolean,
    detail: { String, default: '' },
  },
  setup(props, { emit }) {
    const data = ref('')
    const base64Img = ref(null)
    const uploadImg = ref(null)
    const toolbar = ref({
      customToolbarImage: {
        title: 'Insert image',
        icon: 'v-md-icon-img',
        action(editor) {
          editor.insert(function () {
            base64Img.value = null
            uploadImg.value.value = null
            uploadImg.value.click()
          })
        },
      },
      customToolbarActionImage: {
        title: 'Insert image',
        icon: 'v-md-icon-tip',
        action(editor) {
          editor.insert(function (selected) {
            if (base64Img.value != null) {
              const content = selected || base64Img.value

              return {
                text: `${content}`,
                selected: content,
              }
            }
          })
        },
      },
    })
    const contentChange = (text, html) => {
      emit('contentValue', html)
    }

    const handleUploadImage = (event, insertImage, files) => {
      if (checkFileType(files[0])) {
        fileReader.getBase64(files[0]).then(base64 => {
          data.value =
            data.value + '<div class="image"><img src="' + base64 + '"/></div>'
        })
      }
    }

    const checkFileType = file => {
      if (
        file.type !== 'image/jpeg' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/png'
      ) {
        Modal.error({
          title: 'Error',
          centered: true,
          content: 'System supports file .jpeg .jpg and .png',
        })
        return false
      } else if (file.size / 1024 > config.businessRule.max.photoSize * 100) {
        Modal.error({
          title: 'Error',
          centered: true,
          content:
            'Maximum file size ' +
            config.businessRule.max.photoSize * 100 +
            ' KB',
        })
        return false
      }
      return true
    }

    const onImgFileChange = event => {
      console.log('onImgFileChange : ', event)
      if (checkFileType(event.target.files[0])) {
        fileReader.getBase64(event.target.files[0]).then(base64 => {
          base64Img.value =
            '<div class="image"><img src="' + base64 + '"/></div>'

          document
            .getElementsByClassName(
              'v-md-editor__toolbar-item-customToolbarActionImage',
            )[0]
            .click()
        })
      }
    }

    watch(props, newdetail => {
      data.value = newdetail.detail
    })
    return {
      data,
      contentChange,
      handleUploadImage,
      toolbar,
      uploadImg,
      onImgFileChange,
    }
  },
})
</script>

<style lang="scss">
.v-md-editor {
  box-shadow: none;
  border: 1px solid var(--grey-grey-7);
}
.v-md-editor__toolbar-item-customToolbarActionImage {
  display: none;
}
</style>
