<template>
  <a-button @click="showModal" class="btn-preview">
    <eye-outlined />
    <span class="text-preview-button">Preview Post</span>
  </a-button>
  <a-modal
    v-if="posttype == 'SLA'"
    v-model:visible="visible"
    width="85%"
    class="modal-sla"
    :closable="true"
    :centered="true"
    :mask-closable="false"
    :footer="null"
    @cancel="closeModal()"
  >
    <template #title>
      {{ lbl_prdzt.sla.title }}
      <span class="last-date mt-1">
        ( {{ lbl_prdzt.sla.last_update }} :
        {{ updateDate ? formatDate(updateDate, 'DD/MMM/YYYY') : '-' }}
        )
      </span>
    </template>
    <div class="header-bg">
      <img :src="bg" class="img" />
      <div class="center">
        <div class="title">{{ lbl_prdzt.sla.title }}</div>
        <div class="sub-title">{{ lbl_prdzt.sla.sub_title }}</div>
      </div>
    </div>
    <div class="content">
      <div v-html="content || ''"></div>
    </div>
  </a-modal>
  <a-modal
    v-else
    v-model:visible="visible"
    width="600px"
    class="modal-announcement"
    :title="title"
    :closable="false"
    :centered="true"
    :mask-closable="false"
  >
    <div v-html="content"></div>
    <template #footer>
      <div
        :class="[
          required
            ? 'd-flex justify-content-between text-left'
            : 'd-flex justify-content-end',
        ]"
        style="width: 100%"
      >
        <a-checkbox v-if="required" disabled>{{
          lbl_prdzt.term_and_condition.checkbox
        }}</a-checkbox>
        <div v-if="posttype == 'Term & Condition'" class="d-flex">
          <a-button key="submit" type="primary" disabled @click="handleClose">
            {{ lbl_prdzt.term_and_condition.accept }}
          </a-button>
          <a-button @click="handleClose">
            {{ lbl_prdzt.term_and_condition.unaccept }}</a-button
          >
        </div>

        <div
          v-else
          class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 p-0 text-right"
        >
          <a-button class="close-btn" type="primary" @click="handleClose"
            >Close</a-button
          >
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script>
import { defineComponent, ref, watch } from 'vue'
import Locale from '@/helper/locale/Locale.js'
import bg from '@/assets/imgs/bg_header_sla.png'
export default defineComponent({
  props: {
    page: String,
    title: String,
    updateDate: String,
    content: String,
    required: Boolean,
    posttype: String,
    isVisible: Boolean,
  },
  setup(props, context) {
    const visible = ref(false)
    const lbl_prdzt = ref(null)
    Locale.getBlobLbl().then(lbl => {
      lbl_prdzt.value = lbl
    })

    watch(props, (newVal, oldVal) => {
      console.log('watch : ', newVal, oldVal)
      visible.value = newVal.isVisible
    })

    const showModal = () => {
      if (props.page == 'Create' || props.page == 'Edit') {
        context.emit('checkValidate', true)
      } else {
        visible.value = true
      }
    }
    const handleClose = () => {
      if (props.page == 'Create' || props.page == 'Edit') {
        context.emit('closePreview', false)
        visible.value = false
      } else {
        visible.value = false
      }
    }
    return {
      visible,
      showModal,
      handleClose,
      bg,
      lbl_prdzt,
    }
  },
})
</script>
<style lang="scss" scoped>
.content-announcement {
  button.ant-btn.btn-preview {
    margin: 0 8px;
  }
  @media only screen and (max-width: 976px) {
    button.ant-btn.btn-preview {
      min-width: fit-content;
    }
  }
}
</style>
