<template>
  <Layout>
    <div class="page-login">
      <div class="content-box">
        <div class="content-title content-title--line text-center">
          <h3>Sign in to Announcement</h3>
        </div>
        <a-form :form="form" class="login-form">
          <a-form-item>
            <div class="form-button login-form-ad">
              <a-button
                id="auth_login_signad_button"
                class="bg-microsoft"
                @click="clickLogin()"
              >
                <img src="@/assets/icons/microsoft.svg" />
                <span class="text-microsoft">Sign in with Microsoft</span>
              </a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from '@/components/Layout/LayoutAuth.vue'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var cache = new CacheHelper(ConfigManager.getCacheType())
export default {
  name: 'LogIn',
  inject: ['Ms', 'AccountStore'],
  components: {
    Layout,
  },
  data: function () {
    return {
      idToken: null,
    }
  },
  created: function () {
    if (this.$route.query && this.$route.query.frominvite == 'true') {
      this.$router.push({ name: 'redirect-ms' })
    }
    cache.removeCache('filterUser')
    cache.removeCache('loadSaveList')
    cache.removeCache('logout')
    localStorage.removeItem('bzbsFeatures')
    this.$store.commit('setLoading', false)
  },
  methods: {
    clickLogin() {
      this.$router.push({ name: 'redirect-ms' })
    },
  },
}
</script>
<style lang="scss" scoped>
body.body {
  min-width: 100%;
}
.layout-auth {
  .error-message {
    text-align: center;
    word-break: break-word;
  }
  .form-button {
    text-align: center;
    margin: 20px 0;
    display: block;
    width: 100%;
    overflow: hidden;
    button {
      margin-bottom: 0;
      padding: 11px 10px 10px;
      border: none;
    }
    .bg-microsoft {
      background: #0067b8;
      &:hover,
      &:focus,
      &:active {
        border-color: #0067b8;
        color: var(--color-white);
        opacity: 0.7;
      }
      padding: 8px 15px 7px 15px !important;
      img {
        display: initial;
      }
    }
    .disable {
      opacity: 0.2;
      pointer-events: none;
    }
  }
  .login-form-checkbox {
    margin-bottom: -10px;
    .ant-checkbox-wrapper {
      padding: 0;
    }
  }
  .login-form-ad {
    margin: 0 0 15px;
    padding-top: 20px;
  }
  .box-or {
    padding: 0 15px;
    line-height: 1px;
    .border-or {
      border-bottom: solid 1px #cecece;
    }
    .text-or {
      color: #949494;
    }
  }
  .text-microsoft {
    // line-height: 25px;
    margin-left: 0.25rem !important;
  }
  .fw-600 {
    font-weight: 600;
  }
}
.list-users {
  padding: 0;
  margin: 20px 0 25px;
  li {
    display: inline-block;
    list-style: none;
    padding: 0 7px;
    a {
      color: var(--primary-gold-bzb-primary-bzb);
      padding: 5px;
    }
  }
}
</style>
