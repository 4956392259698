<template>
  <div class="app">
    <router-view />
    <VLoading />
  </div>
</template>
<script>
// import moment from 'moment'
//store
import SideMenuStore from '@/store/SideMenuStore.js'
import TopBarStore from '@/store/TopBarStore'
import { config } from '@/config/bzbsconfig.js'
const newbof = config.bzbBlobUrl

export default {
  watch: {
    $route(to, from) {
      if (!to.hash && to.name !== from.name) {
        this.$store.commit('setLoading', true)
      }
    },
  },
  mounted() {
    document.title = 'Announcement'

    let theme = document.createElement('link')
    theme.type = 'text/css'
    theme.rel = 'stylesheet'
    theme.href =
      newbof +
      '/themebackoffice/assets/' +
      config.businessRule.css.version +
      '/css/theme.css?v=' +
      new Date().getTime()
    document.head.appendChild(theme)
  },
  created: function () {},
  provide: {
    SideMenuStore: SideMenuStore,
    TopBarStore: TopBarStore,
  },
}
</script>
<style lang="scss">
@import '@/style/mainstyle.scss';
</style>
