import CacheHelper from '@/helper/CacheHelper.js'
// import Config from "@/config/ConfigManager.js";
import ConfigManager from '@/config/ConfigManager.js'

import { th } from '@/helper/locale/th.1054.js'
import { en } from '@/helper/locale/en.1033.js'

import _ from 'lodash'
import { config } from '@/config/bzbsconfig.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import Account from '@/helper/AccountHelper'

/**
 * @function getLocale
 * @function getLocaleCode
 * @function getLbl
 * @function getLocaleShort
 * @function setLocale
 */
export default {
  KEY_COOKIE_LOCALE: 'BzbsLocale',
  defaultLocale: {
    code: 1054,
    short: 'th',
  },

  getLocale: function () {
    // var locale = Vue.$cookies.get(this.KEY_COOKIE_LOCALE);
    // var locale = Account.getCacheCustom(this.KEY_COOKIE_LOCALE);
    var cache = new CacheHelper(ConfigManager.getCacheType())
    var locale = cache.getCache(this.KEY_COOKIE_LOCALE)
    if (locale == null) {
      locale = this.defaultLocale
    }
    return locale
  },
  getLocaleCode: function () {
    var localeObj = this.getLocale()
    return localeObj.code
  },
  getLbl: function () {
    if (this.getLocaleCode() == 1033) {
      return en
    } else {
      return th
    }
  },
  getLblString: function () {
    if (this.getLocaleCode() == 1033) {
      return 'en'
    } else {
      return 'th'
    }
  },
  getLocaleShort: function () {
    var localeObj = this.getLocale()
    return localeObj.short
  },
  setLocale: function (localeCode) {
    var localeObj = {
      code: 1054,
      short: 'th',
    }
    if (localeCode == 1033) {
      localeObj = {
        code: 1033,
        short: 'en',
      }
    }

    var cache = new CacheHelper(ConfigManager.getCacheType())
    cache.setCache(this.KEY_COOKIE_LOCALE, localeObj)
  },
  getBlobLbl() {
    return new Promise(resolve => {
      this.connectPrdZTBlob().then(() => {
        var lbl =  JSON.parse(
          localStorage.getItem('setJsonLocal_prdzt_' + this.getLblString()),
        )
        resolve(lbl)
      })
    })
  },
  connectPrdZTBlob() {
    return new Promise(resolve => {
      var date = new Date()
      var urlVersion =
        config.bzbBlobUrl + '/config/language/version.json?v=' + date.getTime()

      this.callBlobVersion(urlVersion).then(() => {
        var version
        try {
          version = JSON.parse(Account.getLanguageVersion())
        } catch {
          version = Account.getLanguageVersion()
        }
        _.forEach(version, (item, keycode) => {
          var date = new Date()
          var urlLocale =
            config.bzbBlobUrl +
            '/config/language/' +
            keycode +
            '/' +
            item.current_version +
            '.json?v=' +
            date.getTime()
          BzbsApi.connectBzbsGetBlob(urlLocale).then(response => {
            localStorage.setItem(
              'setJsonLocal_prdzt_' + keycode,
              JSON.stringify(response.data),
            )
          })
        })

        resolve(true)
      })
    })
  },
  callBlobVersion(urlVersion) {
    return new Promise(resolve => {
      BzbsApi.connectBzbsGetBlob(urlVersion)
        .then(response => {
          Account.setLanguageVersion(JSON.stringify(response.data))
          resolve(response.data)
        })
        .catch(error => {
          resolve(error)
        })
    })
  },
}
