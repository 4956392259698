// import Account from "@/helper/AccountHelper.js";
import Locale from '@/helper/locale/Locale.js'

export default {
  // inject: ["AccountStore"],
  data: function () {
    return {
      lbl: Locale.getLbl(),
      localeCode: Locale.getLocaleCode(),
    }
  },
  methods: {
    switchLang: function (locale) {
      if (this.localeCode == locale) {
        return
      }

      Locale.setLocale(locale)
      this.lbl = Locale.getLbl()
      this.localeCode = Locale.getLocaleCode()
    },
  },
}
