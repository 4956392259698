<template>
  <div class="page-layout page-redirect">
    <div
      id="modal-error"
      class="ant-modal-root ant-modal-confirm ant-modal-confirm-error ant-modal-error ant-modal-confirm ant-modal-confirm-error ant-modal-error hideModal"
    >
      <div class="ant-modal-mask"></div>
      <div
        tabindex="-1"
        role="dialog"
        class="ant-modal-wrap ant-modal-centered"
      >
        <div role="document" class="ant-modal">
          <div class="ant-modal-content">
            <div class="ant-modal-body">
              <div class="ant-modal-confirm-body-wrapper">
                <div class="ant-modal-confirm-body">
                  <i
                    aria-label="icon: close-circle"
                    class="anticon anticon-close-circle"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      data-icon="close-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class=""
                    >
                      <path
                        d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
                      ></path>
                      <path
                        d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                      ></path>
                    </svg>
                  </i>
                  <span id="modal-title" class="ant-modal-confirm-title"
                    >You don't have a permission</span
                  >
                  <div id="modal-content" class="ant-modal-confirm-content">
                    Please login with another account.
                  </div>
                </div>
                <div class="ant-modal-confirm-btns">
                  <button
                    type="button"
                    class="ant-btn ant-btn-primary"
                    @click="close"
                  >
                    <span>OK</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            tabindex="0"
            aria-hidden="true"
            style="width: 0px; height: 0px; overflow: hidden"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import BzbsProfile from '@/core/Account/service/BzbsProfile.js'
import CommonMixin from '@/mixin/CommonMixin'
import AccountHelper from '@/helper/AccountHelper'
var cache = new CacheHelper(ConfigManager.getCacheType())

import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)
myMSAL
  .handleRedirectPromise()
  .then(response => {
    if (response) {
      if (
        response.idTokenClaims['tfp'].toUpperCase() ===
        config.b2cPolicies.names.signUpSignIn.toUpperCase()
      ) {
        handleResponse(response)
      }
    }
  })
  .catch(error => {
    console.log(error)
    myMSAL.loginRedirect(config.loginRequest)
  })

function selectAccount() {
  const currentAccounts = myMSAL.getAllAccounts()
  if (currentAccounts.length < 1) {
    return
  } else if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      account =>
        account.homeAccountId
          .toUpperCase()
          .includes(config.b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(config.b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === config.msalConfig.auth.clientId,
    )

    if (accounts.length > 1) {
      if (
        accounts.every(
          account => account.localAccountId != accounts[0].localAccountId,
        )
      ) {
        signOut()
      }
    }
  }
}

selectAccount()
async function handleResponse(res) {
  if (res !== null) {
    console.log(res)
    document.cookie = 'msToken=' + res.accessToken
    document.cookie = 'msUserData=' + JSON.stringify(res.account)
    var jsonAccount = JSON.stringify({
      name: res.account.name
        ? res.account.name
        : res.account.username.split('@')[0],
      shortedname: res.account.name.includes(' ')
        ? res.account.name.split(' ')[0][0] +
          ' ' +
          res.account.name.split(' ')[1][0]
        : res.account.name[0],
      email: res.account.username,
    })
    document.cookie = 'msAccount=' + JSON.stringify(res.account.idTokenClaims)
    document.cookie = 'account=' + jsonAccount
    BzbsProfile.getPermission()
      .then(result => {
        if (result.data.is_success && result.data.result.features) {
          AccountHelper.setFeatures(result.data.result.features)
          AccountHelper.getMSMenu().then(() => {
            window.location = window.location.origin + '/announcement'
          })
        } else {
          AccountHelper.getMSMenu().then(() => {
            window.location = window.location.origin + '/unauthorized'
          })
        }
      })
      .catch(err => {
        console.log(err)
        if (err.response.data.error[0].id == '401') {
          window.location = window.location.origin + '/unauthorized'
        } else {
          document.getElementById('modal-title').innerHTML =
            'Error ' + err.response.data.error[0].code
          document.getElementById('modal-content').innerHTML =
            err.response.data.error[0].message +
            (err.response.data.transaction_id
              ? '<br>(' + err.response.data.transaction_id + ')'
              : '')
          const d = new Date()
          d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000)
          let expires = 'expires=' + d.toUTCString()

          document.cookie =
            'msToken=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'

          document.getElementById('modal-error').classList.remove('hideModal')
        }
      })
  } else {
    selectAccount()
  }
}

function signOut() {
  const logoutRequest = {
    postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
  }

  myMSAL.logoutRedirect(logoutRequest)
}

export default {
  name: 'RedirectMs',
  mixins: [CommonMixin],
  data() {
    return {
      modalSelectAccount: false,
      account: {},
      options: [],
    }
  },
  metaInfo() {
    return {}
  },
  beforeCreate() {},
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    init() {
      cache.removeCache('logout')
      if (cache.getCache('msToken')) {
        console.log('checkPermission')
        this.checkPermission()
      } else {
        this.signIn()
      }
    },
    signIn() {
      myMSAL.loginRedirect(config.loginRequest)
    },
    checkPermission() {
      AccountHelper.getMSPermission()
        .then(result => {
          if (result.data.is_success && result.data.result.features) {
            AccountHelper.setFeatures(result.data.result.features)
            AccountHelper.getMSMenu().then(() => {
              window.location = window.location.origin + '/announcement'
            })
          } else {
            AccountHelper.getMSMenu().then(() => {
              window.location = window.location.origin + '/unauthorized'
            })
          }
        })
        .catch(() => {
          this.signIn()
        })
    },
    close() {
      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
      }

      myMSAL.logoutRedirect(logoutRequest)
    },
  },
}
</script>

<style lang="scss" scope>
body.body {
  min-width: 100%;
  width: 100%;
  min-height: 100vh;
  .page-redirect {
    .btn-redirect {
      display: block;
      margin: 20vh auto;
    }
    .ant-select {
      min-width: 100%;
    }
  }
  .anticon svg {
    display: inline-block;
  }
}
.hideModal {
  visibility: hidden;
  opacity: 0;
}
</style>
